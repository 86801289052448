/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import Layout from '../components/layout/layout';
import {
  Fonts,
  DetailsHero,
  TrialSummaryStyle,
  MapSectionStyle,
  SocialMediaResults,
  ProtocolSummary,
  DocumentsSection,
  BackButton,
  StudyNotFoundContainer,
  WistiaVideoStyle,
} from '../global.css';
import { Map, InfoWindow } from 'google-maps-react';
import Moment from 'react-moment';
import { gMapApiKey, trialDetailsUrl, tomtomApiKey } from '../constants/config';
import axios from 'axios';
import { Card, CardBody, Spinner, Collapse } from 'reactstrap';
import { Table, Tbody, Thead, Th, Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import NProgress from 'nprogress';
import Modal from '../components/modal/modal';
import {
  FaFacebookF,
  FaLinkedinIn,
  FaPrint,
  FaTwitter,
  FaShareAlt,
} from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import ContactUsForm from '../components/form/contact-us-form';
import { MarkerClusterer } from '../components/map/cluster';
import { FaAngleLeft } from 'react-icons/fa';
import { Popover } from '../components/checkbox/checkbox.css';
import { Criteria } from '../components/criteria/criteria';
import BackToTop from '../components/back-to-top/back-to-top';
import { trackAnalytics } from '../helpers/trackAnalytics';
import WistiaVideo from '../components/player/wistia';
// import ModalSocial from '../containers/modal/modal-social';

class TrialDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      searchResult: null,
      searchParams: null,
      isLoading: true,
      loading: true,
      activeMarker: {},
      selectedPlace: {},
      showingInfoWindow: false,
      modalOpen: false,
      crtModalOpen: false,
      openTable: false,
      collapse: [true, false, true],
      collapseOut: [false, false],
      activeLocations: [],
      showShareSection: false,
      searchLat: null,
      searchLong: null,
      radius: null,
      patientData: false,
      apiBadREsponse: false,
      map: null,
      markersOnTheMap: {},
      eventListenersAdded: false,
      tt: null,
    };
    this.mapRef = null;
    this.cluster = null;
    this.uniquePins = [];
    this.markers = [];
    this.eudraCtId = null;
    this.markerDescriptions = {};
    this.isMobile = false;
    this.googleLoaded = false;
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.openLocationTable = this.openLocationTable.bind(this);
    this.closeLocationTable = this.closeLocationTable.bind(this);
    this.showCrtModal = this.showCrtModal.bind(this);
    this.hideCrtModal = this.hideCrtModal.bind(this);
    this.showShareSection = this.showShareSection.bind(this);
    this.userCountry = null;
  }

  componentDidMount() {
    NProgress.start();
    if (typeof window !== 'undefined') {
      this.isMobile = window.innerWidth && window.innerWidth < 992;
      this.userCountry =
        window.localStorage.getItem('test-user-country') ||
        window.localStorage.getItem('user-country');
      this.setState({
        loading: false,
      });
    }
    if (this.userCountry !== 'CN' && typeof window !== 'undefined')
      this.getGoogleMaps();

    if (this.userCountry === 'CN' && typeof window !== 'undefined') {
      const importTt = async () =>
        await import('@tomtom-international/web-sdk-maps');
      importTt().then(tt => {
        this.setState({ tt });
      });
    }

    this.loadData();
  }

  componentWillUnmount() {
    if (this.markers && this.markers.length && this.cluster) {
      this.markers.forEach(marker => {
        this.cluster.removeMarker(marker);
      });
    }
  }

  getGoogleMaps() {
    window.initMap = () => {
      this.googleLoaded = true;
    };
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${gMapApiKey()}&callback=initMap`;
    script.async = true;
    document.body.appendChild(script);
  }

  filteringUniq(content) {
    let uniq = [];
    return content.filter(item => {
      if (!uniq.includes(item)) {
        uniq.push(item);
        return true;
      }
      return false;
    });
  }

  filteringInterventions = interventions => {
    let uniqCondition = [];
    return interventions.filter(int => {
      uniqCondition.push(int.intervention_type);
      return true;
    });
  };

  filteringOutcomesPrimary = primary_outcomes => {
    let uniqCondition = [];
    return primary_outcomes.filter(prim => {
      uniqCondition.push(prim.outcome_description);
      return true;
    });
  };

  toggle = itemIndex => {
    let collapse = this.state.collapse;
    this.setState({
      collapse: collapse.map((value, index) =>
        index !== itemIndex ? false : !value
      ),
    });
  };

  toggleOutcomes = itemIndex => {
    let collapseOut = this.state.collapseOut;
    this.setState({
      collapseOut: collapseOut.map((value, index) =>
        index !== itemIndex ? false : !value
      ),
    });
  };

  showShareSection() {
    this.setState({
      showShareSection: !this.state.showShareSection,
    });
  }

  showModal() {
    this.setState({
      modalOpen: true,
    });
  }

  hideModal() {
    this.setState({
      modalOpen: false,
    });
  }

  openLocationTable() {
    this.setState({
      openTable: true,
    });
  }

  closeLocationTable() {
    this.setState({
      openTable: false,
    });
  }

  showCrtModal() {
    this.setState({
      crtModalOpen: true,
    });
  }

  hideCrtModal() {
    this.setState({
      crtModalOpen: false,
    });
  }

  trackLink(event, attachment) {
    const { searchResult } = this.state;
    trackAnalytics('DocumentDownload', {
      attachment: attachment.TypeInternal,
      TrialId: searchResult.org_study_id,
      TrialTitle: searchResult.brief_title,
      TrialStatus: searchResult.display_status_internal,
      Conditions: searchResult.conditions.map(cond => cond.InternalValue),
    });

    if (event.target && typeof window !== 'undefined' && window.ga) {
      try {
        window.ga.getAll().forEach(_ga => {
          _ga.send('event', 'Downloads', 'Downloads', event.target.href);
        });
      } catch (ev) {
        console.log(ev);
      }
    }
  }

  calculateDistance(locLat, locLong, paramLong, paramLat) {
    let searchLat = (Math.PI * parseFloat(paramLat).toFixed(6)) / 180;
    let radLocLat = (Math.PI * locLat) / 180;
    let theta = paramLong - locLong;
    let radtheta = (Math.PI * theta) / 180;
    let distance =
      Math.sin(searchLat) * Math.sin(radLocLat) +
      Math.cos(searchLat) * Math.cos(radLocLat) * Math.cos(radtheta);
    distance = Math.acos(distance);
    distance = (distance * 180) / Math.PI;
    distance = distance * 60 * 1.1515;
    return distance;
  }
  sortLocations(paramRes, paramLong, paramLat, paramName) {
    if (paramLong != null && paramLat != null) {
      paramRes.forEach(loc => {
        loc[paramName] = this.calculateDistance(
          loc['Latitude'],
          loc['Longitude'],
          paramLong,
          paramLat
        );
      });

      paramRes.sort(function(a, b) {
        return a.distance - b.distance;
      });
      return paramRes;
    } else {
      return paramRes;
    }
  }

  refreshMarkers() {
    let markersOnTheMap = this.state.markersOnTheMap;
    Object.keys(markersOnTheMap).forEach(function(id) {
      markersOnTheMap[id].remove();
      delete markersOnTheMap[id];
    });
    const { data } = this.props;
    let mapContent = data.trialDetailsJson.mapSection;
    let searchResult = this.state.searchResult;
    let infoWindow;
    this.state.map.querySourceFeatures('point-source').forEach(feature => {
      if (feature.properties && !feature.properties.cluster) {
        let id = feature.properties.id;
        if (!markersOnTheMap[id]) {
          let markerElement = document.createElement('div');
          markerElement.className = 'marker';
          let markerContentElement = document.createElement('div');
          markerContentElement.className = 'marker-content';
          markerContentElement.style.backgroundColor = 'transparent';
          markerElement.appendChild(markerContentElement);
          let iconElement = document.createElement('div');
          iconElement.className = 'marker-icon';
          iconElement.style.backgroundImage = 'url(/icons/map-pin.png)';
          iconElement.style.height = '52px';
          iconElement.style.width = '32px';
          iconElement.style.cursor = 'pointer';
          markerContentElement.appendChild(iconElement);
          let newMarker = new this.state.tt.Marker({
            element: markerElement,
          }).setLngLat(feature.geometry.coordinates);
          newMarker.addTo(this.state.map);
          if (this.markerDescriptions[id]) {
            infoWindow = this.markerDescriptions[id].map(
              (locationDetails, idx) =>
                `<div
                      key={${idx}}
                      className="text-left marker-info-window"
                      style="padding:5px"
                    >
                      <span style="font-weight: bold;">
                      ${
                        locationDetails.Name != null
                          ? locationDetails.Name
                          : 'Not available'
                      }
                      </span>
                      <br />
                      <span style="font-weight: bold;">Condition: </span>
                      ${
                        searchResult.conditions &&
                        searchResult.conditions.length
                          ? this.filteringUniq(
                              searchResult.conditions.map(
                                condition => condition.LookupValue
                              )
                            ).join(', ')
                          : 'Not available'
                      }
                      <br />
                      <span style="font-weight: bold;">Study ID: </span>
                      ${
                        searchResult.org_study_id
                          ? searchResult.org_study_id
                          : 'Not available'
                      }
                      <br />
                      <span style="font-weight: bold;">${
                        mapContent.city
                      } </span>:
                      ${
                        locationDetails.City != null
                          ? locationDetails.City
                          : 'Not available'
                      }
                      <br />
                      <span style="font-weight: bold;">${
                        mapContent.state
                      } </span>:
                      ${
                        locationDetails.StateDisplay != null
                          ? locationDetails.StateDisplay
                          : 'Not available'
                      }
                      <br />
                      <span style="font-weight: bold;">${
                        mapContent.zip
                      } </span>:
                      ${
                        locationDetails.PostalCode != null
                          ? locationDetails.PostalCode
                          : 'Not Available'
                      }
                      <br />
                      <span style="font-weight: bold;">${
                        mapContent.country
                      } </span>:
                      ${
                        locationDetails.CountryDisplay != null
                          ? locationDetails.CountryDisplay
                          : 'Not available'
                      }
                      ${
                        searchResult.overall_contact &&
                        searchResult.overall_contact.phone
                          ? `<div>
                          <a
                            href="tel:${searchResult.overall_contact.phone}"
                          >
                            ${searchResult.overall_contact.phone}
                          </a>
                        </div>`
                          : ''
                      }
                      ${
                        searchResult.overall_contact &&
                        searchResult.overall_contact.email
                          ? `<div>
                          <a
                            href="mailto:${searchResult.overall_contact.email}"
                          >
                            ${searchResult.overall_contact.email}
                          </a>
                        </div>`
                          : ''
                      }
                    </div>`
            )[0];
          }
          let popup = new this.state.tt.Popup({ offset: 30 }).setHTML(
            infoWindow
          );
          newMarker.setPopup(popup);
          markersOnTheMap[id] = newMarker;
        }
      }
    });

    this.setState({
      markersOnTheMap,
    });
  }

  loadData() {
    let { location, navigate, pageContext } = this.props,
      searchParams = null,
      elementId = null,
      searchLat = null,
      searchLong = null,
      searchRadius = null,
      baseLat = null,
      baseLong = null,
      oldId = null;
    try {
      searchParams = new URL(location.href).searchParams;
      elementId = searchParams.get('id');
      oldId = searchParams.get('oldId');
      searchLat = searchParams.get('searchLat');
      searchLong = searchParams.get('searchLong');
      searchRadius = searchParams.get('searchRadius');
      baseLat = searchParams.get('baseLat');
      baseLong = searchParams.get('baseLong');
    } catch (e) {
      elementId = location.search.substr(4);
    }

    if (!elementId || elementId.length === 0) {
      NProgress.done();
      return navigate(`/${pageContext.langKey}/results/`);
    }
    if (this.markers && this.markers.length && this.cluster) {
      this.markers.forEach(marker => {
        this.cluster.removeMarker(marker);
      });
    }
    this.setState({
      isLoading: true,
      searchLat: searchLat,
      searchLong: searchLong,
      radius: searchRadius,
    });

    axios
      .get(trialDetailsUrl(elementId, oldId ? '&Id=' : ''))
      .then(res => {
        NProgress.done();
        const searchResults = res.data;
        let titleToggle = {};
        let bounds;
        if (this.userCountry !== 'CN')
          bounds = new window.google.maps.LatLngBounds();
        this.markers = [];
        this.uniquePins = [];
        this.markerDescriptions = {};
        let resultsWithLocation = 0;
        let patientData;

        trackAnalytics('TrialDetailsResponse', {
          TrialId: elementId,
          searchLat,
          searchLong,
          searchRadius,
          baseLat,
          baseLong,
          Success: true,
        });
        if (
          searchResults &&
          searchResults.study_id &&
          searchResults.protocol_form &&
          searchResults.protocol_form.locations
        ) {
          if (
            searchResults.protocol_form.secondary_ids.length &&
            searchResults.protocol_form.secondary_ids.find(ids =>
              ids.id_type.includes('EudraCT')
            )
          ) {
            this.eudraCtId =
              searchResults.protocol_form.secondary_ids &&
              searchResults.protocol_form.secondary_ids.find(
                ids => ids.id_type === 'EudraCT Number'
              ).id;
          }
          let locations = this.sortLocations(
            searchResults.protocol_form.locations,
            baseLong,
            baseLat,
            'dis',
            null
          );
          patientData = searchResults.protocol_form.fields
            .map(
              item =>
                item.name.includes('Patient Level Data') &&
                item.value.includes('Yes')
            )
            .includes(true);

          locations.forEach(row => {
            if (row.Latitude && row.Longitude) {
              const key =
                '' +
                parseInt(row.Latitude * 10000) +
                '_' +
                parseInt(row.Longitude * 10000);
              if (typeof this.markerDescriptions[key] === 'undefined') {
                this.markerDescriptions[key] = [];
              }
              this.markerDescriptions[key].push(row);
              let loc;
              let marker;
              if (this.userCountry !== 'CN') {
                loc = new window.google.maps.LatLng(
                  row.Latitude,
                  row.Longitude
                );
                bounds.extend(loc);
                marker = new window.google.maps.Marker({
                  position: new window.google.maps.LatLng(
                    row.Latitude,
                    row.Longitude
                  ),
                  title: row.Title,
                  markersClusterKey: key,
                  draggable: false,
                  lat: row.Latitude,
                  long: row.Longitude,
                  distance: row.dis,
                  icon: {
                    url: '/icons/map-pin.png',
                  },
                });

                marker.addListener('click', () => {
                  this.onMarkerClick(marker);
                });
              } else {
                marker = {
                  title: row.Title,
                  markersClusterKey: key,
                  lat: row.Latitude,
                  long: row.Longitude,
                  distance: row.dis,
                };
              }

              if (!this.uniquePins.includes(key)) {
                this.uniquePins.push(key);
                this.markers.push(marker);
              }
              resultsWithLocation++;
            }

            titleToggle[row.Name] = false;
          });
        }

        setTimeout(() => {
          if (this.userCountry === 'CN') {
            const map = this.state.tt.map({
              key: tomtomApiKey(),
              container: 'tomtom-map',
            });
            map.addControl(new this.state.tt.FullscreenControl());
            map.addControl(new this.state.tt.NavigationControl());

            this.setState({
              map,
            });

            const bounds = {
              min_long: 180,
              min_lat: 90,
              max_long: 0,
              max_lat: -90,
            };
            let geoJson = {
              type: 'FeatureCollection',
              features: this.markers.map(function(point, index) {
                if (point.long < bounds.min_long) bounds.min_long = point.long;
                if (point.long > bounds.max_long) bounds.max_long = point.long;
                if (point.lat < bounds.min_lat) bounds.min_lat = point.lat;
                if (point.lat > bounds.max_lat) bounds.max_lat = point.lat;

                return {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [point.long, point.lat],
                  },
                  properties: {
                    id: point.markersClusterKey,
                    name: `Checkpoint ${index}`,
                  },
                };
              }),
            };

            map.on('zoom', () => {
              this.onBoundsChanged();
            });

            map.on('drag', () => {
              this.onBoundsChanged();
            });

            map.on('load', () => {
              map.addSource('point-source', {
                type: 'geojson',
                data: geoJson,
                cluster: true,
                clusterMaxZoom: 14,
                clusterRadius: 50,
              });

              map.addLayer({
                id: 'clusters',
                type: 'circle',
                source: 'point-source',
                filter: ['has', 'point_count'],
                paint: {
                  'circle-color': [
                    'step',
                    ['get', 'point_count'],
                    '#EC619F',
                    4,
                    '#008D8D',
                    7,
                    '#004B7F',
                  ],
                  'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    15,
                    4,
                    20,
                    7,
                    25,
                  ],
                  'circle-stroke-width': 1,
                  'circle-stroke-color': 'white',
                  'circle-stroke-opacity': 1,
                },
              });

              map.addLayer({
                id: 'cluster-count',
                type: 'symbol',
                source: 'point-source',
                filter: ['has', 'point_count'],
                layout: {
                  'text-field': '{point_count_abbreviated}',
                  'text-size': 16,
                },
                paint: {
                  'text-color': 'white',
                },
              });

              map.on('data', e => {
                if (
                  e.sourceId !== 'point-source' ||
                  !map.getSource('point-source').loaded()
                ) {
                  return;
                }

                this.refreshMarkers();

                if (!this.state.eventListenersAdded) {
                  this.setState({
                    eventListenersAdded: true,
                  });
                }
              });

              if (baseLat && baseLong && searchRadius) {
                map.jumpTo({
                  center: [parseFloat(baseLong), parseFloat(baseLat)],
                  zoom:
                    searchRadius === '10'
                      ? 9
                      : searchRadius === '50'
                      ? 8
                      : searchRadius === '100'
                      ? 7
                      : searchRadius === '150'
                      ? 6
                      : searchRadius === '200'
                      ? 5
                      : 4,
                });
              } else {
                map.fitBounds(
                  [
                    [bounds.min_long, bounds.min_lat],
                    [bounds.max_long, bounds.max_lat],
                  ],
                  {
                    padding: { top: 30, bottom: 30, left: 30, right: 70 },
                    maxZoom: 7,
                  }
                );
              }

              map.on('click', 'clusters', e => {
                let features = map.queryRenderedFeatures(e.point, {
                  layers: ['clusters'],
                });
                let clusterId = features[0].properties.cluster_id;
                map
                  .getSource('point-source')
                  .getClusterExpansionZoom(clusterId, (err, zoom) => {
                    if (err) {
                      return;
                    }

                    map.easeTo({
                      center: features[0].geometry.coordinates,
                      zoom: zoom + 0.5,
                    });
                  });
              });

              map.on('mouseenter', 'clusters', () => {
                map.getCanvas().style.cursor = 'pointer';
              });

              map.on('mouseleave', 'clusters', () => {
                map.getCanvas().style.cursor = '';
              });
            });
          }

          if (
            this.mapRef &&
            this.mapRef.map &&
            this.mapRef.map.fitBounds &&
            resultsWithLocation
          ) {
            if (baseLat && baseLong && searchRadius) {
              this.mapRef.map.setCenter({
                lat: parseFloat(baseLat),
                lng: parseFloat(baseLong),
              });
              this.mapRef.map.setZoom(
                searchRadius === '10'
                  ? 9
                  : searchRadius === '50'
                  ? 8
                  : searchRadius === '100'
                  ? 7
                  : searchRadius === '150'
                  ? 6
                  : searchRadius === '200'
                  ? 5
                  : 4
              );
              {
                this.markers.forEach(marker => {
                  if (marker.distance < searchRadius) {
                    marker.setVisible(true);
                  } else {
                    marker.setVisible(false);
                  }
                });
              }
              if (!this.cluster) {
                this.cluster = new MarkerClusterer(
                  this.mapRef.map,
                  this.markers,
                  {
                    minimumClusterSize: 3,
                    averageCenter: true,
                    zoomOnClick: true,
                    imagePath:
                      'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
                  }
                );
                this.cluster.setMaxZoom(
                  searchRadius === '10'
                    ? 8
                    : searchRadius === '50'
                    ? 7
                    : searchRadius === '100'
                    ? 6
                    : searchRadius === '150'
                    ? 5
                    : searchRadius === '200'
                    ? 4
                    : 3
                );
              } else {
                this.cluster.addMarkers(this.markers);
              }
              this.mapRef.map.addListener('click', () => {
                this.onMapClicked();
              });
              this.mapRef.map.addListener('zoom_changed', () => {
                this.onZoomChanged();
              });
              window.google.maps.event.addListener(
                this.mapRef.map,
                'bounds_changed',
                () => {
                  this.onBoundsChanged();
                }
              );
            } else {
              this.mapRef.map.fitBounds(bounds);
              this.mapRef.map.setZoom(2);
              if (!this.cluster) {
                this.cluster = new MarkerClusterer(
                  this.mapRef.map,
                  this.markers,
                  {
                    minimumClusterSize: 2,
                    averageCenter: true,
                    zoomOnClick: true,
                    imagePath:
                      'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
                  }
                );
                this.cluster.setMaxZoom(15);
              } else {
                this.cluster.addMarkers(this.markers);
              }
              this.mapRef.map.addListener('click', () => {
                this.onMapClicked();
              });
              window.google.maps.event.addListener(
                this.mapRef.map,
                'bounds_changed',
                () => {
                  this.onBoundsChanged();
                }
              );
            }
          }
          resultsWithLocation === 1 && this.mapRef && this.mapRef.map !== null
            ? this.mapRef.map.setZoom(8)
            : null;
        }, 2000);

        this.setState({
          isLoading: false,
          searchResult: searchResults.protocol_form,
          id: searchResults.study_id,
          titleToggle,
          totalResults: res.data.Count,
          showingInfoWindow: false,
          activeMarker: {},
          patientData: patientData,
        });
      })
      .catch(error => {
        trackAnalytics('TrialDetailsResponseFailure', {
          Error: error.message,
          TrialId: elementId,
          searchLat,
          searchLong,
          searchRadius,
          baseLat,
          baseLong,
        });
        NProgress.done();
        this.setState({
          apiBadResponse: true,
        });
      });
  }

  onMarkerClick = marker => {
    this.mapRef.map.setCenter(marker.getPosition());
    this.setState({
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };

  onZoomChanged = () => {
    this.markers.forEach(marker => {
      marker.setVisible(true);
    });
    this.setState({
      radius: null,
    });
  };

  onBoundsChanged = () => {
    let currentLocations = [];
    let activeLocations = [];
    let uniqueLocations = [];
    for (var i = 0; i < this.markers.length; i++) {
      if (
        (this.state.map &&
          this.state.map
            .getBounds()
            .contains([this.markers[i].long, this.markers[i].lat])) ||
        (this.mapRef &&
          this.mapRef.map.getBounds().contains(this.markers[i].getPosition()))
      ) {
        let key = '';
        currentLocations.push({
          Latitude: this.markers[i].lat,
          Longitude: this.markers[i].long,
          key: key.concat(this.markers[i].lat, this.markers[i].long),
        });
        // markers[i] in visible bounds
      } else {
        // markers[i] is not in visible bounds
      }
    }
    uniqueLocations = Array.from(new Set(currentLocations.map(a => a.key))).map(
      key => {
        return {
          Latitude: currentLocations.find(s => s.key === key).Latitude,
          Longitude: currentLocations.find(s => s.key === key).Longitude,
        };
      }
    );
    this.state.searchResult.locations.map(item => {
      uniqueLocations.map(activeItem => {
        if (
          item.Latitude.includes(activeItem.Latitude) &&
          item.Longitude.includes(activeItem.Longitude)
        ) {
          if (
            this.state.map ||
            (item.dis < this.state.radius || this.state.radius === null)
          ) {
            activeLocations.push(item);
          }
        }
      });
    });
    this.setState({
      activeLocations: activeLocations,
    });
  };

  onPinClick = (props, marker) => {
    if (marker && marker.getPosition()) {
      this.mapRef.map.setCenter(marker.getPosition());

      // if (this.mapHolderRef) {
      //   window.scrollTo(0, this.mapHolderRef.offsetTop - 80);
      // }
    }

    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true,
    });
  };

  onInfoWindowClose = () => {
    this.setState({
      activeMarker: null,
      showingInfoWindow: false,
    });
  };

  onMapClicked = () => {
    if (this.state.showingInfoWindow)
      this.setState({
        activeMarker: null,
        showingInfoWindow: false,
      });
  };

  indentedToHtmlList(text, indentChar, folderChar, listType, showIcons) {
    // console.log(text.replace(/[^\w\s]/gi, ''));
    // indentChar = indentChar || '\t';

    // eslint-disable-next-line
    // indentChar = indentChar || '\\s\\s';
    indentChar = indentChar || '\\t';
    let indentCharLength = 2;
    folderChar = folderChar || ':';
    listType = listType || 'ul';
    showIcons = !!showIcons;

    let lastDepth,
      mytext = text
        .replace(/[\r\n]+/g, '\n')
        .replace(new RegExp('^( )*-', 'gm'), ''),
      lines = mytext.split(/\r?\n/),
      output = '<' + listType + '>\n',
      depthCounter = new RegExp('^(' + indentChar + '*)(.*)');
    for (let i = 0; i < lines.length; i++) {
      let splitted = lines[i].match(depthCounter);
      let indentStr =
          Array.isArray(splitted) && splitted.length > 2
            ? splitted[1]
            : undefined,
        fileName =
          Array.isArray(splitted) && splitted.length > 2
            ? splitted[2]
            : lines[i],
        currentDepth =
          indentStr === undefined
            ? 0
            : parseInt(indentStr.length / indentCharLength),
        isFolder = fileName.charAt(fileName.length - 1) === folderChar;

      if (!isFolder && typeof lines[i + 1] !== 'undefined') {
        let nextRowsplitted = lines[i + 1].match(depthCounter);
        if (
          nextRowsplitted &&
          nextRowsplitted[1] &&
          parseInt(nextRowsplitted[1].length / indentCharLength) > currentDepth
        ) {
          isFolder = true;
        }
      }

      if (lastDepth === currentDepth) {
        output += '</li>\n';
      } else if (lastDepth > currentDepth) {
        while (lastDepth > currentDepth) {
          output += '</li>\n</' + listType + '>\n</li>\n';
          lastDepth--;
        }
      } else if (lastDepth < currentDepth) {
        output += '\n<' + listType + '>\n';
      }
      output += '<li>';
      if (showIcons) {
        output +=
          '<span class=" glyphicon glyphicon-' +
          (isFolder ? 'folder-open' : 'file') +
          '"></span> ';
      }

      output += fileName;
      lastDepth = currentDepth;
    }

    while (lastDepth >= 0) {
      output += '\n</li>\n</' + listType + '>';
      lastDepth--;
    }

    return output;
  }

  renderTableRow(row, index) {
    const { searchResult } = this.state;
    return (
      <Tr
        key={index}
        className={
          'location-row ' +
          (index % 2 === 1 ? 'row-unpaired' : 'row-paired') +
          (index <= 3 && this.state.openTable === false
            ? ' showRow'
            : this.state.openTable === true
            ? ' showTotalRows'
            : ' hideRow')
        }
      >
        <Td className={'location'}>
          {[
            `${row.CountryDisplay}`,
            `${row.StateDisplay}`,
            `${row.City}`,
            `${row.PostalCode}`,
          ]
            .filter(x => x !== 'null')
            .join(', ')}
        </Td>
        <Td className={'status-line'}>
          <div
            className={
              `${row.StatusDisplayInternal}`.toLocaleLowerCase() +
              ' status-content'
            }
          >
            {row.StatusMapped || 'N/A'}
          </div>
        </Td>
        <Td className={'modal-status'}>
          <div className={'print-contact'}>
            {row.Contact.phone ? <p>{row.Contact.phone}</p> : undefined}
            {row.Contact.email ? <p>{row.Contact.email}</p> : undefined}
          </div>
          <Modal
            hasButton={true}
            open={this.state.modalOpen}
            showModal={() => {
              this.showModal();
              trackAnalytics('TrialContactClick', {
                TrialId: searchResult.org_study_id,
                PositionInList: -1,
                TrialTitle: searchResult.brief_title,
                TrialStatus: searchResult.display_status_internal,
                LocationPosition: index,
                Conditions: searchResult.conditions.map(
                  cond => cond.InternalValue
                ),
              });
            }}
            hideModal={() => this.hideModal()}
            button={'Contact Us'}
            buttonType="simple"
            class="view-more-modal"
            type={'contactUs'}
          >
            <ContactUsForm
              itemId={searchResult.org_study_id}
              title={searchResult.brief_title}
              hideModal={() => this.hideModal()}
              headerTitle={'Contact Us'}
              locationName={'Site Location: '}
              locationAddress={[
                `${row.Name}`,
                `${row.CountryDisplay}`,
                `${row.StateDisplay}`,
                `${row.City}`,
                `${row.PostalCode}`,
              ]
                .filter(x => x !== 'null')
                .join(', ')}
            />
          </Modal>
        </Td>
      </Tr>
    );
  }
  getMasking = (maskingInfo, masking) => {
    let maskingValues = [];
    let maskingCounter = ['Single', 'Double', 'Triple', 'Quadruple'];
    if (maskingInfo.masked_subject === 'true')
      maskingValues.push('Participant');
    if (maskingInfo.masked_caregiver === 'true')
      maskingValues.push('Care Provider');
    if (maskingInfo.masked_investigator === 'true')
      maskingValues.push('Investigator');
    if (maskingInfo.masked_assessor === 'true')
      maskingValues.push('Outcomes Assessor');
    if (maskingInfo.no_masking === 'true') {
      return 'None (Open Label)';
    }
    if (maskingValues.length) {
      return `${maskingCounter[maskingValues.length - 1]} (${maskingValues
        .map(val => val)
        .join(', ')})`;
    }
    if (masking === 'Open Label') {
      return 'None (Open Label)';
    }
    return 'Not Available';
  };
  renderResultsTable() {
    if (!this.state.searchResult && !this.state.searchResult.length) {
      return undefined;
    }
    !this.state.collapse.includes(false)
      ? setTimeout(() => {
          this.setState({
            collapse: [false, false, false],
          });
        }, 1000)
      : null;
    let locations = this.sortLocations(
      this.state.activeLocations,
      this.state.searchLong,
      this.state.searchLat,
      'distance'
    );
    return (
      <Table className={this.state.activeLocations.length > 10 ? 'scroll' : ''}>
        <Thead className={'thead-mobile'}>
          <Tr>
            <Th className={'headerElement first'}>Locations</Th>
            <Th className={'headerElement'}>Status</Th>
            <Th className={'headerElement last'}>
              Contact Us for <br />
              Site Information{' '}
            </Th>
          </Tr>
        </Thead>
        <Tbody>{locations.map(this.renderTableRow.bind(this))}</Tbody>
      </Table>
    );
  }

  renderContent = () => {
    const { data } = this.props;
    const { activeMarker, searchResult, isLoading } = this.state;
    let mapContent = data.trialDetailsJson.mapSection;

    if (isLoading) {
      return (
        <div>
          <Spinner />
        </div>
      );
    }

    if (!searchResult) return null;

    const EUCTNumber = searchResult.secondary_ids.find(
      ids => ids.id_domain === 'EU CT number'
    );

    return (
      <>
        <DetailsHero>
          <div className={'background-style'} />
          <div
            id="hero"
            className={`container-fluid ${
              searchResult.org_study_id === '213299'
                ? 'details-page-with-video'
                : 'details-page'
            }
            `}
          >
            <Row className={'top-navigation-row'}>
              <Col lg={8} md={6} sm={6} xs={8} className={'status-bar-desktop'}>
                <button
                  className="go-back-button"
                  onClick={event => {
                    event.preventDefault();
                    window.history.go(-1);
                  }}
                >
                  <FaAngleLeft />
                  <p className={'go-back-btn-text'}>
                    {data.trialDetailsJson.returnSearch}
                  </p>
                </button>
              </Col>
            </Row>
            <Row className={'data-display-row'}>
              <Col md={8} className={'hero-sec'}>
                {searchResult.fields.map((item, index) =>
                  item.name.includes('Relinquished') ? (
                    <p key={index} className={'relin-text'}>
                      {item.value}
                    </p>
                  ) : null
                )}
                <div className={'hero-title-col'}>
                  {searchResult.brief_title ? (
                    <h1
                      className={
                        'details-title' +
                        (innerHeight >= 320 ? ' big-element' : '')
                      }
                    >
                      {searchResult.brief_title}
                    </h1>
                  ) : (
                    ''
                  )}
                  <div className={'overall-section'}>
                    <div
                      className={
                        'status-display ' +
                        `${searchResult.display_status_internal}`.toLocaleLowerCase()
                      }
                    >
                      {searchResult.study_type.includes('Expanded Access')
                        ? searchResult.overall_status
                        : searchResult.display_status_internal ===
                          'RECSTATUS_RELIQUISHED'
                        ? 'No Longer a GSK Study'
                        : searchResult.display_status}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={4} sm={12} xs={12} className={'desktop-social'}>
                <div className="d-flex align-items-end flex-column h-100">
                  <div>
                    <button
                      className={
                        this.state.showShareSection === false
                          ? 'mobile-share open'
                          : 'mobile-share closed'
                      }
                      onClick={this.showShareSection}
                    >
                      <div className={'shareSvgPlaceholder'}>
                        <FaShareAlt className={'mobileShare-btn'} />
                      </div>
                      <p>Share this search</p>
                    </button>
                    <SocialMediaResults
                      className={
                        this.state.showShareSection === false
                          ? 'closedShare '
                          : 'openShare '
                      }
                    >
                      <p className={'mobileShareSection'}>
                        Share this Clinical Study
                      </p>
                      <div className={'media-row details-media-row'}>
                        <a
                          href={`mailto:?subject=${
                            data.trialDetailsJson.shareByEmail.subject
                          } ${
                            searchResult.conditions &&
                            searchResult.conditions.length
                              ? searchResult.conditions
                                  .map(cond => cond.LookupValue)
                                  .join(', ')
                              : ''
                          }&body=${data.trialDetailsJson.shareByEmail.body} ${
                            searchResult.conditions &&
                            searchResult.conditions.length
                              ? searchResult.conditions
                                  .map(cond => cond.LookupValue)
                                  .join(', ')
                              : ''
                          }. ${data.trialDetailsJson.shareByEmail.learnMore} ${
                            typeof window !== 'undefined' &&
                            window.location &&
                            window.location.href
                              ? encodeURIComponent(window.location.href)
                              : ''
                          }`}
                          rel="noopener noreferrer"
                          target="_top"
                        >
                          <MdEmail />
                        </a>
                        <button
                          className={'print-button'}
                          onClick={event => {
                            event.preventDefault();
                            window.print();
                          }}
                        >
                          <FaPrint />
                        </button>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            'https://www.linkedin.com/shareArticle?mini=true&url=' +
                            encodeURIComponent(window.location.href)
                          }
                        >
                          <FaLinkedinIn />
                        </a>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            'https://www.facebook.com/sharer/sharer.php?u=' +
                            encodeURIComponent(window.location.href)
                          }
                        >
                          <FaFacebookF />
                        </a>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            'https://twitter.com/share?url=' +
                            encodeURIComponent(window.location.href)
                          }
                        >
                          <FaTwitter />
                        </a>
                      </div>
                    </SocialMediaResults>
                    <div className={'modal-contact'}>
                      <Modal
                        hasButton={true}
                        open={this.state.modalOpen}
                        showModal={() => {
                          this.showModal();
                          trackAnalytics('TrialContactClick', {
                            TrialId: searchResult.org_study_id,
                            PositionInList: -1,
                            TrialTitle: searchResult.brief_title,
                            TrialStatus: searchResult.display_status_internal,
                            LocationPosition: -1,
                            Conditions: searchResult.conditions.map(
                              cond => cond.InternalValue
                            ),
                          });
                        }}
                        hideModal={() => this.hideModal()}
                        button={'Contact Us'}
                        buttonType="simple"
                        class="view-more-modal"
                        type={'contactUs'}
                      >
                        <ContactUsForm
                          itemId={searchResult.org_study_id}
                          title={searchResult.brief_title}
                          hideModal={() => this.hideModal()}
                          headerTitle={'Contact Us'}
                        />
                      </Modal>
                    </div>
                  </div>
                  {searchResult.modified_date && (
                    <div className="last-updated-date d-none d-md-flex flex-column mt-auto">
                      <p className="mt-3 text-nowrap">{`${data.trialDetailsJson.lastUpdatedDate} ${searchResult.modified_date}`}</p>
                    </div>
                  )}
                </div>
              </Col>
              {searchResult.modified_date && (
                <div className="last-updated-date d-flex d-md-none flex-column w-100 align-items-start align-items-sm-end">
                  <p>{`${data.trialDetailsJson.lastUpdatedDate} ${searchResult.modified_date}`}</p>
                </div>
              )}
            </Row>

            <div className={'info-study'}>
              <Row style={{ width: '100%' }} className="info-study-inner">
                <Col
                  className={
                    'id-cols info-study-col desk-id' +
                    (this.state.patientData ? ' tablet-border' : '')
                  }
                >
                  <h3>
                    {data.trialDetailsJson.identifier}{' '}
                    <img
                      src={'/icons/tooltip.svg'}
                      alt={''}
                      id={'study-id'}
                    ></img>
                  </h3>
                  <Popover
                    placement="auto-end"
                    trigger="hover"
                    modifiers={{ flip: { behavior: ['bottom'] } }}
                    target={'study-id'}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          data.trialDetailsJson.popoversText.studyId
                            .childMarkdownRemark.html,
                      }}
                    />
                  </Popover>
                  <p>{searchResult.org_study_id}</p>
                  {searchResult.attachments &&
                  searchResult.attachments.length ? (
                    <a
                      className={'doc-info'}
                      onClick={() =>
                        this.setState({
                          collapse: [false, false, true],
                        })
                      }
                      href={'#documents-section'}
                    >
                      See Study Documents Below
                    </a>
                  ) : null}
                </Col>
                <Col className={'id-cols info-study-col'}>
                  <h3>
                    {data.trialDetailsJson.clinicalID}:{' '}
                    <img src={'/icons/tooltip.svg'} alt={''} id={'ctGov'}></img>
                  </h3>
                  <Popover
                    placement="auto-end"
                    trigger="hover"
                    modifiers={{ flip: { behavior: ['bottom'] } }}
                    target={'ctGov'}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          data.trialDetailsJson.popoversText.ctGov
                            .childMarkdownRemark.html,
                      }}
                    />
                  </Popover>
                    {searchResult.nct_number ? (
                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://clinicaltrials.gov/study/${searchResult.nct_number}?rank=1`}
                      >
                        {searchResult.nct_number}
                      </a>
                  </p>
                  ) : <p>Not Applicable</p>}
                  {searchResult.HasResults === 'true' &&
                  searchResult.IsReleasedToCtGov === 'true' ? (
                    <a
                      className={'doc-info'}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://clinicaltrials.gov/ct2/show/results/${searchResult.nct_number}?term=${searchResult.nct_number}&rank=1`}
                    >
                      See Results Summary
                    </a>
                  ) : null}
                </Col>
                <Col className={'id-cols info-study-col'}>
                  <h3>
                    {data.trialDetailsJson.EudraCtID}:
                    <img
                      src={'/icons/tooltip.svg'}
                      alt={''}
                      id={'eudraCT'}
                    ></img>
                  </h3>
                  <Popover
                    placement="auto-end"
                    trigger="hover"
                    modifiers={{ flip: { behavior: ['bottom'] } }}
                    target={'eudraCT'}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          data.trialDetailsJson.popoversText.eudraCT
                            .childMarkdownRemark.html,
                      }}
                    />
                  </Popover>
                    {this.eudraCtId ? (
                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://www.clinicaltrialsregister.eu/ctr-search/search?query=${this.eudraCtId}`}
                        title={this.eudraCtId}
                      >
                        {this.eudraCtId}
                      </a>
                  </p>
                  ) : <p>Not Applicable</p>}
                </Col>
                <Col
                  className={
                    'id-cols info-study-col' +
                    (this.state.patientData
                      ? ' tablet-noborder'
                      : ' border-corection')
                  }
                >
                  <h3>
                    EU CT Number:
                    <img src={'/icons/tooltip.svg'} alt={''} id={'EUCT'}></img>
                  </h3>
                  <Popover
                    placement="auto-end"
                    trigger="hover"
                    modifiers={{ flip: { behavior: ['bottom'] } }}
                    target={'EUCT'}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          '<h2>EU CT Number</h2>\n<p>The unique reference for a trial given by European medial agency under EU clinical trial regulations.</p>',
                      }}
                    />
                  </Popover>
                    {EUCTNumber ? (
                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://euclinicaltrials.eu/search-for-clinical-trials/?lang=en&EUCT=${EUCTNumber.id}`}
                        title={EUCTNumber.id}
                      >
                        {EUCTNumber.id}
                      </a>
                    </p>
                  ) : <p>Not Applicable</p>}
                </Col>
                {this.state.patientData && (
                  <Col
                    className={
                      this.state.patientData
                        ? 'patient-data info-study-col'
                        : ''
                    }
                  >
                    {searchResult.fields.map((item, index) => (
                      <div key={index} className={'patientLink'}>
                        {item.name === 'Patient Level Data' &&
                        item.value === 'Yes' ? (
                          <a
                            className={'patient-link'}
                            key={searchResult.org_study_id}
                            href={`https://search.vivli.org/?search=${searchResult.org_study_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Patient Level Data Available
                          </a>
                        ) : null}
                      </div>
                    ))}
                  </Col>
                )}
              </Row>
            </div>
            {searchResult.org_study_id === '213299' ? (
              <WistiaVideoStyle>
                <WistiaVideo code="l2ecc5ug0s" />
              </WistiaVideoStyle>
            ) : null}
          </div>
        </DetailsHero>
        <TrialSummaryStyle>
          <Fonts className={'fonts-cont'}>
            <Row className={'trial-summary-row'}>
              <div className={'shadow-back'} />
              <Col lg={12} md={12} className={'summaryTitle'}>
                <h2>{data.trialDetailsJson.trialSummary}</h2>
              </Col>
              {searchResult.conditions ? (
                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  className={'cards-col bordered'}
                >
                  <Card className={'detail-card'}>
                    <CardBody>
                      <div className={'img-holder'}>
                        <img
                          className={'summary-icon condition'}
                          src={'/icons/condition.png'}
                          alt={'Clinical Trial Medical Condition and Disease'}
                        />
                      </div>
                      <h3>
                        {data.trialDetailsJson.summarySection.conditionsDisplay}
                        <img
                          src={'/icons/tooltip.svg'}
                          alt={''}
                          id={'cond'}
                        ></img>
                      </h3>
                      <Popover
                        placement="auto-end"
                        trigger="hover"
                        modifiers={{ flip: { behavior: ['bottom'] } }}
                        target={'cond'}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              data.trialDetailsJson.popoversText.cond
                                .childMarkdownRemark.html,
                          }}
                        />
                      </Popover>
                      <h4>
                        {searchResult.conditions &&
                        searchResult.conditions.length
                          ? this.filteringUniq(
                              searchResult.conditions.map(
                                condition => condition.LookupValue
                              )
                            ).join(', ')
                          : 'N/A'}
                      </h4>
                    </CardBody>
                  </Card>
                </Col>
              ) : (
                undefined
              )}
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={4}
                className={'cards-col bordered'}
              >
                <Card className={'detail-card'}>
                  <CardBody>
                    <div className={'img-holder'}>
                      <img
                        className={'summary-icon condition'}
                        src={'/icons/product.png'}
                        alt={'Clinical Trial Medical Condition and Disease'}
                      />
                    </div>
                    <h3>
                      {data.trialDetailsJson.summarySection.product}
                      <img
                        src={'/icons/tooltip.svg'}
                        alt={''}
                        id={'product'}
                      ></img>
                    </h3>
                    <Popover
                      placement="auto-end"
                      trigger="hover"
                      modifiers={{ flip: { behavior: ['bottom'] } }}
                      target={'product'}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            data.trialDetailsJson.popoversText.product
                              .childMarkdownRemark.html,
                        }}
                      />
                    </Popover>
                    <h4>
                      {searchResult.product_name &&
                      searchResult.product_name.length
                        ? searchResult.product_name.split(';').join(', ')
                        : 'N/A'}
                    </h4>
                  </CardBody>
                </Card>
              </Col>
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={4}
                className={'cards-col bordered end'}
              >
                <Card className={'detail-card'}>
                  <CardBody>
                    <div className={'img-holder'}>
                      <img
                        className={'summary-icon condition'}
                        src={'/icons/collaborator.png'}
                        alt={'Clinical Trial Medical Condition and Disease'}
                      />
                    </div>
                    <h3>
                      {data.trialDetailsJson.summarySection.collaborators}
                      <img
                        src={'/icons/tooltip.svg'}
                        alt={''}
                        id={'collabolators'}
                      ></img>
                    </h3>
                    <Popover
                      placement="auto-end"
                      trigger="hover"
                      modifiers={{ flip: { behavior: ['bottom'] } }}
                      target={'collabolators'}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            data.trialDetailsJson.popoversText.collabolators
                              .childMarkdownRemark.html,
                        }}
                      />
                    </Popover>
                    <h4>
                      {searchResult.sponsors.collaborators &&
                      searchResult.sponsors.collaborators.length
                        ? this.filteringUniq(
                            searchResult.sponsors.collaborators.map(
                              condition => condition
                            )
                          ).join(', ')
                        : 'N/A'}
                    </h4>
                  </CardBody>
                </Card>
              </Col>
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={4}
                className={'cards-col bordered'}
              >
                <Card className={'detail-card'}>
                  <CardBody>
                    <div className={'img-holder'}>
                      <img
                        className={'summary-icon condition'}
                        src={'/icons/date.png'}
                        alt={'Clinical Trial Medical Condition and Disease'}
                      />
                    </div>
                    <h3>
                      {data.trialDetailsJson.summarySection.dates}
                      <img
                        src={'/icons/tooltip.svg'}
                        alt={''}
                        id={'date'}
                      ></img>
                    </h3>
                    <Popover
                      placement="auto-end"
                      trigger="hover"
                      modifiers={{ flip: { behavior: ['bottom'] } }}
                      target={'date'}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            data.trialDetailsJson.popoversText.date
                              .childMarkdownRemark.html,
                        }}
                      />
                    </Popover>
                    {searchResult.start_date ? (
                      <h4 className={'dates '}>
                        <Moment format="MMMM YYYY ">
                          {searchResult.start_date}
                        </Moment>
                        {' To '}
                        {searchResult.last_follow_up_date ? (
                          <Moment format=" MMMM YYYY ">
                            {searchResult.last_follow_up_date}
                          </Moment>
                        ) : (
                          'N/A'
                        )}
                      </h4>
                    ) : (
                      <h4>{'N/A'}</h4>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={4}
                className={'cards-col bordered'}
              >
                <Card className={'detail-card'}>
                  <CardBody>
                    <div className={'img-holder'}>
                      <img
                        className={'summary-icon condition'}
                        src={'/icons/type.png'}
                        alt={'Clinical Trial Medical Condition and Disease'}
                      />
                    </div>
                    {searchResult.study_type &&
                    searchResult.study_type.length > 1 ? (
                      <Popover
                        placement="auto-end"
                        trigger="hover"
                        modifiers={{ flip: { behavior: ['bottom'] } }}
                        target={'type'}
                      >
                        {searchResult.study_type.includes('Interventional') ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                data.trialDetailsJson.popoversText
                                  .interventional.childMarkdownRemark.html,
                            }}
                          />
                        ) : searchResult.study_type.includes(
                            'Observational'
                          ) ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                data.trialDetailsJson.popoversText.observational
                                  .childMarkdownRemark.html,
                            }}
                          />
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                data.trialDetailsJson.popoversText.expended
                                  .childMarkdownRemark.html,
                            }}
                          />
                        )}
                      </Popover>
                    ) : null}
                    <h3>
                      {data.trialDetailsJson.summarySection.studyType}
                      {searchResult.study_type &&
                      searchResult.study_type.length > 1 ? (
                        <img
                          src={'/icons/tooltip.svg'}
                          alt={''}
                          id={'type'}
                        ></img>
                      ) : null}
                    </h3>
                    <h4>
                      {searchResult.study_type && searchResult.study_type
                        ? searchResult.study_type
                        : 'N/A'}
                    </h4>
                  </CardBody>
                </Card>
              </Col>
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={4}
                className={'cards-col bordered end'}
              >
                <Card className={'detail-card'}>
                  <CardBody>
                    <div className={'img-holder'}>
                      <img
                        className={'summary-icon phase'}
                        src={'/icons/phase.png'}
                        alt={'Clinical Trial Phases'}
                      />
                    </div>
                    <Popover
                      placement="auto-end"
                      trigger="hover"
                      modifiers={{ flip: { behavior: ['bottom'] } }}
                      target={'phase'}
                    >
                      {searchResult.display_phase
                        .replace(/Phase /g, '')
                        .includes('1') ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              data.trialDetailsJson.popoversText.phase1
                                .childMarkdownRemark.html,
                          }}
                        />
                      ) : searchResult.display_phase
                          .replace(/Phase /g, '')
                          .includes('2') ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              data.trialDetailsJson.popoversText.phase2
                                .childMarkdownRemark.html,
                          }}
                        />
                      ) : searchResult.display_phase
                          .replace(/Phase /g, '')
                          .includes('3') ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              data.trialDetailsJson.popoversText.phase3
                                .childMarkdownRemark.html,
                          }}
                        />
                      ) : searchResult.display_phase
                          .replace(/Phase /g, '')
                          .includes('4') ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              data.trialDetailsJson.popoversText.phase4
                                .childMarkdownRemark.html,
                          }}
                        />
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              data.trialDetailsJson.popoversText.phaseNA
                                .childMarkdownRemark.html,
                          }}
                        />
                      )}
                    </Popover>
                    <h3>
                      {data.trialDetailsJson.summarySection.phase}
                      <img
                        src={'/icons/tooltip.svg'}
                        alt={''}
                        id={'phase'}
                      ></img>
                    </h3>
                    {searchResult.display_phase ? (
                      <h4>
                        {searchResult.display_phase.replace(/Phase /g, '')}{' '}
                      </h4>
                    ) : (
                      <h4>{'N/A'}</h4>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={6}
                xs={12}
                className={
                  'cards-col ' + ['All', 'Both'].indexOf(searchResult.gender) >=
                  0
                    ? 'both' + ' gender-icon'
                    : `${searchResult.gender}`.toLocaleLowerCase() +
                      ' gender-icon'
                }
              >
                <Card className={'detail-card'}>
                  <CardBody>
                    <div className={'img-holder'}>
                      <img
                        className={'summary-icon gender'}
                        src={'/icons/gender.png'}
                        alt={'Clinical Trial Gender and Sex Female and Male'}
                      />
                    </div>
                    <h3>{data.trialDetailsJson.summarySection.gender}</h3>
                    <h4 className={'results-gender'}>
                      {searchResult.display_gender || 'N/A'}
                    </h4>
                  </CardBody>
                </Card>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={6}
                xs={12}
                className={'cards-col text-requirements'}
              >
                <Card className={'detail-card'}>
                  <CardBody>
                    <div className={'img-holder'}>
                      <img
                        className={'summary-icon age'}
                        src={'/icons/age.png'}
                        alt={'Clinical Trial Age Range or Limit'}
                      />
                    </div>
                    <h3>{data.trialDetailsJson.summarySection.age}</h3>
                    <h4 className={'results-ageRange'}>
                      {searchResult.minimum_age_raw &&
                      searchResult.maximum_age_raw &&
                      !searchResult.minimum_age_raw.includes('N/A') &&
                      !searchResult.maximum_age_raw.includes('N/A')
                        ? `${searchResult.minimum_age_raw} - ${searchResult.maximum_age_raw}`
                        : searchResult.minimum_age_raw &&
                          !searchResult.minimum_age_raw.includes('N/A')
                        ? `${searchResult.minimum_age_raw} + `
                        : !searchResult.maximum_age_raw.includes('N/A') &&
                          searchResult.maximum_age_raw
                        ? `N/A - ${searchResult.maximum_age_raw}`
                        : 'N/A'}
                    </h4>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12} sm={6} md={4} lg={4} className={'cards-col'}>
                <Card className={'detail-card'}>
                  <CardBody>
                    <div className={'img-holder'}>
                      <img
                        className={'summary-icon capsula'}
                        src={'/icons/healthvol.png'}
                        alt={'Clinical Trial Drug Treatment'}
                      />
                    </div>
                    <h3>
                      {data.trialDetailsJson.summarySection.acceptVolunteers}
                    </h3>

                    <h4>{searchResult.healthy_volunteers || 'N/A'}</h4>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Fonts>
        </TrialSummaryStyle>
        <MapSectionStyle>
          <div className="container-fluid details-page mapSection">
            <Fonts className={'fonts-cont'}>
              <h2 onClick={this.toggle.bind(this, 0)}>
                Locations{' '}
                <img
                  src="/icons/dropdown_arrow.svg"
                  alt="icon"
                  className={
                    this.state.collapse[0] === true ? 'arrow-position' : ''
                  }
                />
              </h2>
              <Collapse isOpen={this.state.collapse[0]}>
                <div>
                  <div className={'map-holder'}>
                    <div
                      ref={ref => (this.mapHolderRef = ref)}
                      className="map-locations"
                      id={'map-locations'}
                    >
                      {this.userCountry === 'CN' ? (
                        <div
                          id="tomtom-map"
                          className="tomtom-map"
                          style={{
                            height: '100%',
                            width: '100%',
                            position: 'relative',
                          }}
                        ></div>
                      ) : this.googleLoaded ? (
                        <Map
                          ref={ref => {
                            this.mapRef = ref;
                          }}
                          google={window.google}
                          containerStyle={{
                            width: '100%',
                            height: '100%',
                            position: 'relative',
                          }}
                          className={'results-map'}
                          zoom={2}
                          initialCenter={{
                            lat:
                              parseFloat(
                                searchResult.locations.length
                                  ? searchResult.locations[0].Latitude
                                  : 50.43
                              ) || 50.43,
                            lng:
                              parseFloat(
                                searchResult.locations.length
                                  ? searchResult.locations[0].Longitude
                                  : 26.08
                              ) || 26.081807,
                          }}
                        >
                          <InfoWindow
                            maxWidth={350}
                            marker={activeMarker}
                            onClose={this.onInfoWindowClose}
                            visible={this.state.showingInfoWindow}
                          >
                            {activeMarker &&
                            activeMarker.markersClusterKey &&
                            this.markerDescriptions[
                              activeMarker.markersClusterKey
                            ] ? (
                              this.markerDescriptions[
                                activeMarker.markersClusterKey
                              ].map((locationDetails, idx) => (
                                <div
                                  key={idx}
                                  className="text-left marker-info-window"
                                  style={{ padding: '5px' }}
                                >
                                  <strong>
                                    {locationDetails.Name != null
                                      ? locationDetails.Name
                                      : 'Not available'}
                                  </strong>
                                  <br />
                                  <strong>{'Condition: '}</strong>
                                  {searchResult.conditions &&
                                  searchResult.conditions.length
                                    ? this.filteringUniq(
                                        searchResult.conditions.map(
                                          condition => condition.LookupValue
                                        )
                                      ).join(', ')
                                    : 'Not available'}
                                  <br />
                                  <strong>{'Study ID: '}</strong>
                                  {searchResult.org_study_id
                                    ? searchResult.org_study_id
                                    : 'Not available'}
                                  <br />
                                  <strong>{mapContent.city}</strong>:{' '}
                                  {locationDetails.City != null
                                    ? locationDetails.City
                                    : 'Not available'}
                                  <br />
                                  <strong>{mapContent.state}</strong>:{' '}
                                  {locationDetails.StateDisplay != null
                                    ? locationDetails.StateDisplay
                                    : 'Not available'}
                                  <br />
                                  <strong>{mapContent.zip}</strong>:{' '}
                                  {locationDetails.PostalCode != null
                                    ? locationDetails.PostalCode
                                    : 'Not Available'}
                                  <br />
                                  <strong>{mapContent.country}</strong>:{' '}
                                  {locationDetails.CountryDisplay != null
                                    ? locationDetails.CountryDisplay
                                    : 'Not available'}
                                  {searchResult.overall_contact &&
                                  searchResult.overall_contact.phone ? (
                                    <>
                                      <br />
                                      <a
                                        href={`tel:${searchResult.overall_contact.phone}`}
                                      >
                                        {searchResult.overall_contact.phone}
                                      </a>
                                    </>
                                  ) : (
                                    undefined
                                  )}
                                  {searchResult.overall_contact &&
                                  searchResult.overall_contact.email ? (
                                    <>
                                      <br />
                                      <a
                                        href={`mailto:${searchResult.overall_contact.email}`}
                                      >
                                        {searchResult.overall_contact.email}
                                      </a>
                                    </>
                                  ) : (
                                    undefined
                                  )}
                                </div>
                              ))
                            ) : (
                              <div />
                            )}
                          </InfoWindow>
                        </Map>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  {this.state.searchResult.locations &&
                  this.state.searchResult.locations.length ? (
                    <div className={'location-holder'}>
                      {this.renderResultsTable()}
                    </div>
                  ) : (
                    <p className={'missing'}>{'No locations available.'}</p>
                  )}
                </div>
              </Collapse>
            </Fonts>
          </div>
        </MapSectionStyle>

        <ProtocolSummary>
          <h2 onClick={this.toggle.bind(this, 1)}>
            {data.trialDetailsJson.protocol_summary}{' '}
            <img
              src="/icons/dropdown_arrow.svg"
              alt="icon"
              className={
                this.state.collapse[1] === true ? 'arrow-position' : ''
              }
            />
          </h2>
          <Collapse
            isOpen={this.state.collapse[1]}
            className={'protocol-collapse'}
          >
            <h3 className={'protocol-title'}>
              {data.trialDetailsJson.protocolSection.study_description}:
            </h3>
            <p className={'protocol-description'}>
              {searchResult.brief_summary
                ? `${searchResult.brief_summary}`
                : 'Not Available'}
            </p>
            <div className={'section-holder'}>
              <h4>{data.trialDetailsJson.protocolSection.primary_purpose}:</h4>{' '}
              <h4 className={'displayed-data'}>
                {searchResult.interventional_subtype
                  ? `${searchResult.interventional_subtype}`
                  : 'Not Available'}
              </h4>
            </div>
            <div className={'section-holder'}>
              <h4>{data.trialDetailsJson.protocolSection.allocation}:</h4>{' '}
              <h4 className={'displayed-data'}>
                {searchResult.allocation
                  ? `${searchResult.allocation}`
                  : 'Not Available'}
              </h4>
            </div>
            <div className={'section-holder'}>
              <h4>{data.trialDetailsJson.protocolSection.study_design}:</h4>{' '}
              <h4 className={'displayed-data'}>
                {searchResult.assignment
                  ? `${searchResult.assignment}`
                  : 'Not Available'}
              </h4>
            </div>
            <div className={'section-holder'}>
              <h4>{data.trialDetailsJson.protocolSection.masking}:</h4>{' '}
              <h4 className={'displayed-data'}>
                {this.getMasking(
                  searchResult.MaskingInfo,
                  searchResult.masking
                )}
              </h4>
            </div>
            <div
              className={
                'collapse-holder primary' +
                (this.state.collapseOut[0] === false ? ' closed' : ' open')
              }
            >
              <h2
                className={'sub-ordered-collapse'}
                color="primary"
                onClick={this.toggleOutcomes.bind(this, 0)}
              >
                {data.trialDetailsJson.protocolSection.primary_outcomes}
                <img
                  src="/icons/dropdown_arrow.svg"
                  alt="icon"
                  className={
                    this.state.collapseOut[0] === true ? 'arrow-position' : ''
                  }
                />
              </h2>
              <Collapse
                isOpen={this.state.collapseOut[0]}
                className={'outcomes-holder'}
              >
                {searchResult.primary_outcomes &&
                searchResult.primary_outcomes.length !== 0 ? (
                  this.filteringOutcomesPrimary(
                    searchResult.primary_outcomes
                  ).map((primary_outcomes, index) => (
                    <ul key={index}>
                      {primary_outcomes.outcome_measure ? (
                        <li>{primary_outcomes.outcome_measure}</li>
                      ) : (
                        <h4>Not Available</h4>
                      )}
                      {primary_outcomes.outcome_time_frame ? (
                        <li>
                          Timeframe: {primary_outcomes.outcome_time_frame}
                        </li>
                      ) : (
                        undefined
                      )}
                      <br />
                    </ul>
                  ))
                ) : (
                  <h4 className={'not-available'}>Not Available</h4>
                )}
              </Collapse>
            </div>
            <div className={'collapse-holder secondary'}>
              <h2
                className={'sub-ordered-collapse'}
                onClick={this.toggleOutcomes.bind(this, 1)}
              >
                {data.trialDetailsJson.protocolSection.secondary_outcomes}
                <img
                  src="/icons/dropdown_arrow.svg"
                  alt="icon"
                  className={
                    this.state.collapseOut[1] === true ? 'arrow-position' : ''
                  }
                />
              </h2>
              <Collapse
                className={'outcomes-holder'}
                isOpen={this.state.collapseOut[1]}
              >
                {searchResult.primary_outcomes &&
                searchResult.primary_outcomes.length !== 0 ? (
                  this.filteringOutcomesPrimary(
                    searchResult.secondary_outcomes
                  ).map((secondary_outcomes, index) => (
                    <ul key={index}>
                      {secondary_outcomes.outcome_measure ? (
                        <li>{secondary_outcomes.outcome_measure}</li>
                      ) : (
                        <h4 className={'not-available'}>Not Available</h4>
                      )}
                      {secondary_outcomes.outcome_time_frame ? (
                        <li>
                          Timeframe: {secondary_outcomes.outcome_time_frame}
                        </li>
                      ) : (
                        undefined
                      )}
                      <br />
                    </ul>
                  ))
                ) : (
                  <h4 className={'not-available'}>Not Available</h4>
                )}
              </Collapse>
            </div>
            <div className={'section-holder'}>
              <h4>
                {data.trialDetailsJson.protocolSection.interventions}:
                <img
                  src={'/icons/tooltip.svg'}
                  alt={''}
                  id={'interventions'}
                  className={'tooltip-img'}
                ></img>
                <Popover
                  placement="auto-end"
                  trigger="hover"
                  modifiers={{ flip: { behavior: ['bottom'] } }}
                  target={'interventions'}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        data.trialDetailsJson.protocolSection.interventionsMd
                          .childMarkdownRemark.html,
                    }}
                  />
                </Popover>
              </h4>
              <h4 className={'displayed-data'}>
                <ul>
                  {searchResult.interventions &&
                  searchResult.interventions.length !== 0 ? (
                    this.filteringInterventions(searchResult.interventions).map(
                      (int, index) => (
                        <li key={index}>
                          {int.intervention_type}: {int.intervention_name}
                        </li>
                      )
                    )
                  ) : (
                    <h4 className={'displayed-data'}>Not Available</h4>
                  )}
                </ul>
              </h4>
            </div>
            <div className={'section-holder'}>
              <h4>{data.trialDetailsJson.protocolSection.criteriaText}:</h4>
              <h4 className={'displayed-data'}>
                <Modal
                  hasButton={true}
                  open={this.state.modalOpen}
                  showModal={() => {
                    this.showModal();
                    trackAnalytics('InclusionExclusionClick', {
                      TrialId: searchResult.org_study_id,
                      PositionInList: -1,
                      TrialTitle: searchResult.brief_title,
                      TrialStatus: searchResult.display_status_internal,
                      Conditions: searchResult.conditions.map(
                        cond => cond.InternalValue
                      ),
                    });
                  }}
                  hideModal={() => this.hideModal()}
                  button={data.trialDetailsJson.protocolSection.criteria_button}
                  buttonType="simple"
                  class="criteria-modal"
                  type={'criteria'}
                >
                  <Criteria
                    textInc={searchResult.inclusion}
                    textExc={searchResult.exclusion}
                    hideModal={() => this.hideModal()}
                  />
                </Modal>
              </h4>
            </div>
            <div className={'print-criteria'}>
              <h2>{'Inclusion Criteria'}</h2>
              {searchResult.inclusion ? (
                <pre>{searchResult.inclusion}</pre>
              ) : (
                <pre>{'Sorry there is no Inclusion Criteria'}</pre>
              )}

              <h2 className={'exclusion'}>{'Exclusion Criteria'}</h2>
              {searchResult.exclusion ? (
                <pre className={'second-pre'}>{searchResult.exclusion}</pre>
              ) : (
                <pre>{'Sorry there is no Exclusion Criteria'}</pre>
              )}
            </div>
            <div className={'section-holder enrollment'}>
              <h4>{data.trialDetailsJson.protocolSection.enrollment}:</h4>
              <h4 className={'displayed-data'}>
                {searchResult.enrollment
                  ? `${searchResult.enrollment}`
                  : 'Not Available'}
              </h4>
            </div>
            {searchResult.citations.length ? (
              <div className={'section-holder citations'}>
                <h4>{'Clinical Publications:'}</h4>
                <div>
                  {searchResult.citations.map((citation, index) => (
                    <h4 key={index} className={'displayed-data citation'}>
                      {citation.citation}
                    </h4>
                  ))}
                </div>
              </div>
            ) : null}
          </Collapse>
        </ProtocolSummary>

        <DocumentsSection id={'documents-section'}>
          <h2 onClick={this.toggle.bind(this, 2)}>
            {data.trialDetailsJson.study_doc}{' '}
            <img
              src="/icons/dropdown_arrow.svg"
              alt="icon"
              className={
                this.state.collapse[2] === true ? 'arrow-position' : ''
              }
            />
          </h2>
          <Collapse isOpen={this.state.collapse[2]}>
            {searchResult.attachments &&
              searchResult.attachments !== null &&
              searchResult.attachments.some(
                attachment =>
                  attachment.TypeInternal === 'ATTACHTYPE_STUDY_SYNOPSIS'
              ) && (
                <p>
                  {data.trialDetailsJson.studyDocumentsAdditionalText}{' '}
                  <a
                    href={`mailto: ${data.trialDetailsJson.studyDocumentsAdditionalEmail}`}
                    rel="noopener noreferrer"
                  >
                    {data.trialDetailsJson.studyDocumentsAdditionalEmail}
                  </a>
                  <span>.</span>
                </p>
              )}
            <div className={'document-sec'}>
              { !['213351'].includes(searchResult.org_study_id) && searchResult.attachments && searchResult.attachments !== null ? (
                searchResult.attachments.map((attachment, index) => (
                  <div key={index} className={'documents-holder'}>
                    <div className={'attachments'}>
                      <a
                        className={'documents'}
                        rel="noopener noreferrer"
                        target="_blank"
                        onClick={ev => this.trackLink(ev, attachment)}
                        // Some chars in the attachment Url are already encoded, but we need all special chars encoded for IE11 support
                        // So we need to decode it before the encoding to be sure it will not break
                        href={encodeURI(decodeURI(attachment.Url))}
                      >
                        <img src={'/icons/file-pdf.svg'} alt={'pdf-icon'}></img>
                        {(attachment.TypeInternal ==
                          'ATTACHTYPE_PLAIN_LANGUAGE_SUMMARY' &&
                        attachment.Description
                          ? attachment.Description
                          : attachment.TypeDisplay) +
                          (attachment.Language
                            ? ` (${attachment.Language})`
                            : '')}
                      </a>
                    </div>
                  </div>
                ))
              ) : (
                <p className={'no-doc-message'}>{'No documents available.'}</p>
              )}
            </div>
          </Collapse>
        </DocumentsSection>
        <BackButton>
          <button
            className="go-back-button"
            onClick={event => {
              event.preventDefault();
              window.history.go(-1);
            }}
          >
            <FaAngleLeft />
            <p className={'go-back-btn-text'}>
              {data.trialDetailsJson.returnSearch}
            </p>
          </button>
        </BackButton>
      </>
    );
  };
  renderStudyNotFound = () => {
    let { data } = this.props;
    return (
      <Fonts>
        <StudyNotFoundContainer>
          <h1 className={'main-title'}>{data.studyNotFoundJson.title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: data.studyNotFoundJson.content.childMarkdownRemark.html,
            }}
          />
        </StudyNotFoundContainer>
      </Fonts>
    );
  };

  render() {
    const content = this.renderContent();
    const studyNotFound = this.renderStudyNotFound();
    let { apiBadResponse } = this.state;

    return this.state.loading || !this.state.searchResult ? (
      apiBadResponse ? (
        <Layout>
          <div
            style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            <div style={{ maxWidth: '1110px', width: '100%' }}>
              {studyNotFound}
            </div>
          </div>
        </Layout>
      ) : (
        ''
      )
    ) : (
      <Layout
        meta={
          this.state.searchResult
            ? {
                pageTitle: `GSK - ${this.state.searchResult.brief_title}`,
                pageDescription: this.state.searchResult.official_title,
                canonical: window.location.href.split('&')[0],
              }
            : {}
        }
      >
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <div
            style={{ maxWidth: '1110px', width: '100%' }}
            className={'details-page-mobile'}
          >
            {content}
          </div>
        </div>
        <BackToTop />
      </Layout>
    );
  }
}

TrialDetails.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  navigate: PropTypes.func,
  google: PropTypes.object,
};

export default TrialDetails;

export const query = graphql`
  query ResultsDetailsQuery {
    studyNotFoundJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    trialDetailsJson {
      title
      identifier
      clinicalID
      EudraCtID
      additionalQuestions
      returnSearch
      socialMedia
      lastUpdatedDate
      trialPurpose
      keyRequirement
      mapSectionTitle
      mapLocation
      trialSummary
      trialDesign
      protocol_summary
      study_doc
      studyDocumentsAdditionalText
      studyDocumentsAdditionalEmail
      summarySection {
        conditionsDisplay
        product
        collaborators
        dates
        phase
        studyType
        gender
        age
        acceptVolunteers
      }
      mapSection {
        city
        state
        zip
        country
      }
      shareByEmail {
        subject
        body
        learnMore
      }
      protocolSection {
        study_description
        primary_purpose
        allocation
        study_design
        study_classification
        masking
        primary_outcomes
        secondary_outcomes
        interventions
        criteria_button
        enrollment
        criteriaText
        interventionsMd {
          childMarkdownRemark {
            html
          }
        }
      }
      popoversText {
        studyId {
          childMarkdownRemark {
            html
          }
        }
        ctGov {
          childMarkdownRemark {
            html
          }
        }
        eudraCT {
          childMarkdownRemark {
            html
          }
        }
        cond {
          childMarkdownRemark {
            html
          }
        }
        product {
          childMarkdownRemark {
            html
          }
        }
        collabolators {
          childMarkdownRemark {
            html
          }
        }
        date {
          childMarkdownRemark {
            html
          }
        }
        phase1 {
          childMarkdownRemark {
            html
          }
        }
        phase2 {
          childMarkdownRemark {
            html
          }
        }
        phase3 {
          childMarkdownRemark {
            html
          }
        }
        phase4 {
          childMarkdownRemark {
            html
          }
        }
        phaseNA {
          childMarkdownRemark {
            html
          }
        }
        interventional {
          childMarkdownRemark {
            html
          }
        }
        observational {
          childMarkdownRemark {
            html
          }
        }
        expended {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    sitePage {
      path
      internalComponentName
      component
      componentPath
      id
    }
  }
`;
